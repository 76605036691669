<template>
  <article
    class="card"
    :class="[
      `card-${direction}`,
      {
        plain,
        'theme-navy': !plain || !finalProps.image.metadata.sketch,
        'page-card': page,
        'no-quote': type === 'page' && !finalProps.quote,
      },
    ]"
    :style="style"
  >
    <AtomsIcon
      v-if="type === 'article'"
      class="card-type"
      name="document-text"
    />
    <transition v-if="type === 'video' && video?.url" name="card-video">
      <div v-if="active" class="card-video">
        <AtomsButton
          :icon="{ name: 'x-mark' }"
          plain
          class="card-video-close"
          title="Close video"
          @click="active = false"
        />
        <video
          ref="videoElement"
          class="card-video-el"
          preload="none"
          :poster="poster"
          :src="video.url"
          controls
        />
      </div>
    </transition>
    <AtomsButton
      class="card-btn row"
      :to="finalProps.to"
      :class="{ plain, 'theme-dark': !plain }"
      plain
      @click="handleClick()"
    >
      <AtomsImage
        v-if="finalProps.image && finalProps.image.id"
        class="card-image"
        :alt="title"
        :image="finalProps.image"
        :zoom="false"
      />
      <transition name="card-content">
        <span v-if="!active" class="card-content column">
          <LazyAtomsBlockquote
            v-if="type !== 'article' && finalProps.quote"
            class="card-quote"
          >
            {{ finalProps.quote }}
          </LazyAtomsBlockquote>
          <span
            v-if="
              type === 'article' ||
              (type === 'page' && !finalProps.quote && finalProps.text)
            "
            class="card-title"
            v-text="finalProps.title"
          />
          <span
            v-if="finalProps.text && (!page || type === 'article')"
            class="card-text"
            v-text="finalProps.text"
          />
          <span v-if="!page" class="card-button">
            {{
              button
                ? button
                : type === 'video'
                ? 'Play video'
                : type === 'article' || type === 'story'
                ? `Read ${type}`
                : !finalProps.quote && finalProps.text
                ? 'Read more'
                : finalProps.title
            }}
            <SvgoPlay
              v-if="type === 'video'"
              class="card-icon card-icon-video"
            />
            <AtomsIcon v-else name="arrow-long-right" />
          </span>
        </span>
      </transition>
    </AtomsButton>
  </article>
</template>

<script lang="ts" setup>
import type { Video, SanityImage } from '~~/types'

const { getImageUrl } = useSanityImages()

const props = defineProps({
  slug: String,
  title: String,
  image: Object as () => SanityImage,
  article: Object,
  to: [String, Object, undefined],
  quote: String,
  text: String,
  delay: { type: Number, default: 0 },
  page: Boolean,
  layout: String,
  name: String,
  button: String,
  plain: Boolean,
  type: String,
  video: Object as () => Video,
})
const poster = props.image && getImageUrl(props.image, { width: 1200 })

const direction = computed(() => {
  const names = {
    first: 'horizontal',
    second: 'horizontal',
    sidebar: 'vertical',
    third: 'vertical',
  }
  if (!props.layout) return 'vertical'
  const [_, cols, equal] = props.layout.split('-')
  switch (cols) {
    case '1':
      return 'horizontal'
    case '2':
      return equal ? 'horizontal' : names[props.name]
    case '3':
      return 'vertical'
  }
})

const style = computed(() => {
  const background =
    props.plain &&
    props.image &&
    props.image.metadata.isOpaque &&
    !props.image.metadata.sketch &&
    props.image.metadata.palette.darkMuted.background

  return {
    background,
    animationDelay: props.delay + 's',
  }
})
const videoElement = ref<HTMLVideoElement>(null)

const active = ref(false)

function handleClick() {
  active.value = true

  setTimeout(() => {
    videoElement.value?.play()
  }, 500)
}
const finalProps = computed(() =>
  props.type !== 'article'
    ? {
        ...props,
        to: (props.type !== 'video' && props.to) || null,
      }
    : {
        ...props,
        ...props.article,
      }
)
</script>

<style lang="scss">
.theme-dark,
.theme-navy {
  .card.card-horizontal .card-button {
    color: var(--body);
  }
  .card .nuxt-button {
    color: var(--secondary);

    &:hover,
    &:focus,
    &:active {
      color: var(--emph);
    }
  }
}

.card {
  position: relative;
  max-width: 100%;

  animation: appearY 1s ease-out backwards paused;

  &:not(.spotlight) {
    width: 24rem;
    border-radius: var(--br-items-sm);
    height: 36rem;
    overflow: hidden;
    background: var(--background);
  }

  @include media('<1200px') {
    // width: 20rem;
    height: 32rem;
  }

  @include media('<tablet') {
    align-self: center;
  }

  &-type.icon {
    position: absolute;
    top: min(6%, 1em);
    left: min(6%, 1.5em);
    z-index: 800;
    color: var(--body);
  }

  &-video {
    position: absolute;
    inset: 0;
    width: 100%;
    border-radius: inherit;
    z-index: 1;
    transition: czn.transition(0.6s ease 0.3s, (opacity));
    overflow: hidden;

    &-el {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }

    &-close {
      position: absolute;
      inset: 1em 1em auto auto;
      font-size: 1em;
      z-index: 100;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
    &-leave-active {
      transition: czn.transition(0.6s ease, (opacity));
    }
  }

  &.card-horizontal {
    @include media('>=desktop') {
      border-radius: var(--br-items-xs);
      // height: 540px;
      width: 100%;
      height: 33rem;

      .card {
        &-image.image {
          height: 100%;
          width: auto;
          margin-right: -40%;
          max-height: unset;
          display: inline;
          border-radius: inherit 0 0 inherit;
          z-index: 0;

          &:not(.sketch) {
            mask-image: linear-gradient(90deg, black 40%, transparent);
          }
        }
        &-title,
        &-text {
          font-size: 1.4em;
          align-self: flex-start;
          width: 75%;
          margin-left: auto;
        }
        &-text {
          margin-bottom: 14%;
        }
        &-quote {
          max-width: 20ch;
          font-size: 1.6em;
          margin-bottom: 1em;
          animation-play-state: inherit;

          @include media('<1200px') {
            font-size: 1.6em;
          }
        }
        &-content {
          padding-left: 30%;
          padding-top: min(6%, 1em);
        }
        &-button.nuxt-button {
          color: var(--body);
        }
      }

      &:hover,
      &:focus {
        .card-button {
          transform: translateX(2rem);
        }
      }
      &:active {
        .card-button {
          transform: translateX(2rem) scale(0.9);
        }
      }
    }
  }

  .card {
    &-btn {
      &.nuxt-button {
        position: relative;
        display: flex;
        align-items: flex-end;
        border-radius: inherit;
        width: 100%;
        height: 100%;

        // &:not(.plain) {
        //   background: var(--primary);
        //   color: var(--body-on-dark);
        //   border-radius: var(--br-items-lg);
        // }
      }
    }
    &-image.image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 80%;
      height: auto;
      max-height: 80%;
      max-width: 100%;
      border-radius: inherit;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      transition: czn.transition(var(--transition), (transform, opacity));

      animation-name: appearScale;

      &.sketch {
        opacity: 0.4;
      }

      &:not(.sketch) {
        mask-image: linear-gradient(black 40%, transparent);
      }
    }

    &-content {
      width: 100%;
      padding: min(6%, 1em) min(6%, 1.5em);
      padding-top: 20%;
      transition: czn.transition(0.5s ease-out, (transform, opacity));
      z-index: 200;

      @include media('<desktop') {
        padding-top: min(6%, 1em);
      }

      &-enter-from {
        .card-quote {
          transform: scale(0) !important;
        }
        .card-button {
          transform: translateX(-14rem) !important;
        }
      }
      &-leave-to {
        .card-quote {
          transform: scale(2) !important;
        }
        .card-button {
          transform: translateX(14rem) !important;
        }
        opacity: 0;
      }
    }

    &-title,
    &-text,
    &-quote,
    &-button {
      margin: 0;
      width: 94%;
    }
    &-quote {
      margin-bottom: 14%;

      &::before,
      &::after {
        content: none;
      }
    }
    &-title {
      font: {
        size: 1.8rem;
        family: var(--font-emph);
        style: italic;
      }
      color: var(--emph);
      line-height: 1.2;
    }
    &-text {
      color: var(--body);
      font-weight: 300;
      font-size: 1.4rem;
      text-align: left;
      margin: 0.5em 0 1em;
      margin-bottom: 14%;
    }

    &-button {
      display: flex;
      text-align: right;
      margin-left: auto;
      justify-content: flex-end;
      font-weight: 300;
      align-items: center;
      transition: czn.transition(var(--transition), (transform, color));

      .icon {
        margin-left: 0.25rem;
        transition: czn.transition(var(--transition), (transform));
      }
    }

    &-icon {
      transition: czn.transition(var(--transition), (transform));
      transform-origin: 30% 50%;
      &-video {
        float: right;
        margin-left: -0.5em;
        margin-right: -0.5em;
        width: 5em;
        z-index: -1;
        color: var(--secondary);
      }

      // &-hover {
      //   fill: var(--navy);
      //   transform: scale(0);
      //  transition: czn.transition(var(--transition), (transform));
      //  transform-origin: 30% 50%;
      // }
    }
  }

  &.page-card {
    background: black;
    border-radius: var(--br-items-lg);
    width: 20rem;
    max-height: 30rem;

    .card {
      &-image {
        min-height: 100%;
        opacity: 0.8;
      }

      &-content {
        padding-bottom: 1em;
      }

      &-title {
        font-size: 1.4em;
      }
      &-button {
        display: none;
      }
    }

    &:hover,
    &:focus,
    &:active {
      .card-image {
        opacity: 1;
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    .card {
      &-image.image {
        &:not(.sketch) {
          transform: scale(1.1);
        }
        &.sketch {
          opacity: 0.6;
        }
      }
      &-quote {
        transform: scale(1.1);

        .blockquote-pre,
        .blockquote-post {
          transform: scale(1.1);
          opacity: 0.4;
        }
      }
      &-icon {
        transform: translateX(-0.5rem) rotate(120deg);
      }
      // &-icon-hover {
      //   fill: var(--navy);
      //   transform: scale(0.8);
      // }
      &-button {
        color: var(--emph);
        transform: translateX(1rem);
      }
    }
  }
  &:active {
    .card {
      &-image {
        &:not(.sketch) {
          transform: scale(1.1);
        }
        &.sketch {
          opacity: 0.6;
        }
      }
      &-quote {
        transform: scale(1.05);

        .blockquote-pre,
        .blockquote-post {
          transform: scale(1);
          opacity: 0.15;
        }
      }
      &-icon {
        transform: translateX(0.45rem) rotate(120deg) scale(1.1);
      }
      // &-icon-hover {
      //   fill: var(--navy);
      //   transform: scale(0.8);
      // }
      &-button {
        color: var(--emph);
        transform: translateX(1rem) scale(0.9);
      }
    }
  }

  &.plain,
  &.no-quote {
    .card-button {
      color: var(--emph);

      font-size: 2.5rem;
      font-family: var(--font-emph);
      font-style: italic;

      .icon {
        margin-left: 1rem;
      }
    }
  }

  &.plain {
    border-radius: none;
    color: var(--body);

    .card {
      &-image {
      }

      &-quote {
        color: var(--emph);
      }

      &-text {
        color: var(--emph);
      }

      &-icon {
        color: var(--emph);
      }
    }
  }
}
</style>
